import { Box } from "reflexbox"
import { css } from "@emotion/core"
import React from "react"

const BoxOverImage = ({ children, width }) => {
  return (
    <Box
      css={css`
        position: absolute;
        width: ${width ? width : 85}%;
        height: 100%;
        display: flex;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
        align-items: center;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
      `}
    >
      {children}
    </Box>
  )
}

export default BoxOverImage
