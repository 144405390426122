import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { css } from "@emotion/core"
import Layout from "~/components/common/Layout"
import HeroVideo from "~/components/page/HeroVideo"
import { Infobox, InfoboxDetailed } from "~/components/page/Infobox"
import { Box, Flex } from "reflexbox"
import { Heading, Text } from "~/components/common/Typography"
import { getBackLink, getHeaderTitles, getNextLink, getNextTitle } from "~/services/pages"
import withProgress from "~/services/withProgress"
import GraphicWithText from "~/components/page/GraphicWithText"
import { mq } from "~/components/common/theme"
import Copyright from "~/components/common/Copyright"
import Img from "gatsby-image"
import BoxOverImage from "~/components/common/BoxOverImage"
import Source from "~/components/common/Source"
import CustomPlayer from "~/components/common/CustomPlayer"

const Page = ({ myPage = 1, mySubpage = 3 }) => {
  const data = useStaticQuery(graphql`
      query {
          hero: file(name: { eq: "2.4HERO" }) {
              childImageSharp {
                  fluid(maxWidth: 1440, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
          video: file(
              name: { eq: "ADIDAS_DigitalCurriculum_Video3_9x16_CTM_190919_3bit" }
          ) {
              name
              publicURL
          }
          graphic1: file(name: { eq: "2.4a-ADIDAS-school" }) {
              childImageSharp {
                  fluid(maxWidth: 500, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
          graphic2: file(name: { eq: "2.4b-ADIDAS-contribution" }) {
              childImageSharp {
                  fluid(maxWidth: 500, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
          graphic3: file(name: { eq: "2.4c-ADIDAS-strengths" }) {
              childImageSharp {
                  fluid(maxWidth: 500, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
          graphic4: file(name: { eq: "2.4d-ADIDAS-belonging" }) {
              childImageSharp {
                  fluid(maxWidth: 500, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
          graphic5: file(name: { eq: "2.4e-ADIDAS-selfeficacy" }) {
              childImageSharp {
                  fluid(maxWidth: 500, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
          graphic6: file(name: { eq: "2.4f-ADIDAS-problemsolving" }) {
              childImageSharp {
                  fluid(maxWidth: 500, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
          graphic7: file(name: { eq: "2.4g-ADIDAS-groupactivities" }) {
              childImageSharp {
                  fluid(maxWidth: 500, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
          graphic8: file(name: { eq: "2.4h-ADIDAS-highexpectations" }) {
              childImageSharp {
                  fluid(maxWidth: 500, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
          graphic9: file(name: { eq: "2.4i-ADIDAS-family" }) {
              childImageSharp {
                  fluid(maxWidth: 500, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
          quote1: file(name: { eq: "ADIDAS_PHOTOTREATMENT_2.4.1" }) {
              childImageSharp {
                  fluid(maxWidth: 900) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
          quote2: file(name: { eq: "ADIDAS_PHOTOTREATMENT_2.4.2" }) {
              childImageSharp {
                  fluid(maxWidth: 900) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
          quote3: file(name: { eq: "ADIDAS_PHOTOTREATMENT_2.4.3" }) {
              childImageSharp {
                  fluid(maxWidth: 900) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
          quote4: file(name: { eq: "ADIDAS_PHOTOTREATMENT_2.4.4" }) {
              childImageSharp {
                  fluid(maxWidth: 900) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
      }
  `)

  const {
    hero,
    video,
    graphic1,
    graphic2,
    graphic3,
    graphic4,
    graphic5,
    graphic6,
    graphic7,
    graphic8,
    graphic9,
    quote1,
    quote2,
    quote3,
    quote4,
  } = data

  const graphicMq = mq({
    width: ["150px", "200px", "200px"],
    height: ["150px", "200px", "200px"],
  })

  return (
    <Layout
      myPage={myPage}
      mySubpage={mySubpage}
      header={getHeaderTitles(myPage, mySubpage)}
      footer={{
        backLink: `${getBackLink(myPage, mySubpage)}`,
        nextText: `NEXT: ${getNextTitle(myPage, mySubpage)}`,
        nextLink: `${getNextLink(myPage, mySubpage)}`,
      }}
    >
      <HeroVideo
        as={"section"}
        headingLarge="Coaches set the tone for how girls interact with each other on and off the field."
        image={hero}
        bg={"white"}
      />

      <Infobox as={"section"} uppercase>
        Now that you understand the importance of coach-to-athlete
        relationships, let’s shift focus on athlete-to-athlete relationships.
      </Infobox>

      <InfoboxDetailed
        as={"section"}
        headerText={
          "One of the best things a girl can take away from sports is a friendship that lasts a lifetime."
        }
        headerMaxWidth={"desktop"}
        pb={0}
      >
        <Flex
          flexDirection={["column", "row"]}
          maxWidth={"desktop"}
          // alignItems={"start"}
          mx={"auto"}
          mt={6}
        >
          <Box width={["100%", "50%"]}>
            <Box width={"100%"} maxWidth={["253px", "337px"]} mx={"auto"}>
              <CustomPlayer url={video.publicURL} videoLabel={video.name} vertical></CustomPlayer>
            </Box>
          </Box>
          <Box
            width={["100%", "50%"]}
            textAlign={["center", "left"]}
            pl={[0, 7]}
            pr={[0, 3]}
            fontSize={["16px", "18px"]}
            lineHeight={["24px", "36px"]}
          >
            <Text mt={[5, 0]}>
              We often assume that when girls are on a team, they naturally make
              friends, but this doesn’t always happen. As a coach, you can
              encourage the development of friendships, which is especially
              important for girls who may have a harder time connecting with new
              people.
            </Text>
            <Text>
              The goal is to increase the number of social relationships among
              the athletes. If a girl has no friends, try to help her leave with
              one friend. If a girl has some friends, try to help her leave with
              more friends. And if she has many friends, help her strengthen
              those friendships.
            </Text>
          </Box>
        </Flex>
      </InfoboxDetailed>

      <InfoboxDetailed
        as={"section"}
        headerText={"So how do you encourage these relationships?"}
        headerBackground={"transparent"}
        headerMaxWidth={"1250px"}
        contentMaxWidth={"desktop"}
      >
        <Box
          css={css`
            //text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
          `}
        >
          <Flex
            flexDirection={["column", "row"]}
            alignItems="center"
            justifyItems={"center"}
          >
            <Box
              width={["100%"]}
              mt={5}
              mr={[0, 4]}
              css={css`
                position: relative;
              `}
            >
              <BoxOverImage>
                <Flex
                  width={"100%"}
                  flexDirection={"column"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Text
                    doBreak
                    uppercase
                    fontSize={["80px", "80px", "100px"]}
                    lineHeight={["116px", "116px", "141px"]}
                    color={"white"}
                    fontFamily={"PermanentMarkerRegular"}
                    my={0}
                  >
                    Pair
                  </Text>
                  <Box width={"100%"}>
                    <Text
                      doBreak
                      uppercase
                      fontSize={["14px", "14px", "20px"]}
                      lineHeight={1.5}
                      color={"white"}
                      fontFamily={"AdineuePROBoldWeb"}
                    >
                      Pair girls together for a session or a season.
                    </Text>
                  </Box>
                </Flex>
              </BoxOverImage>
              <Img fluid={quote1.childImageSharp.fluid} loading={"eager"}></Img>
            </Box>
            <Box
              width={["100%"]}
              ml={[0, 4]}
              mt={5}
              css={css`
                position: relative;
              `}
            >
              <BoxOverImage>
                <Flex
                  width={"100%"}
                  flexDirection={"column"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Text
                    doBreak
                    uppercase
                    fontSize={["80px", "80px", "100px"]}
                    lineHeight={["116px", "116px", "141px"]}
                    color={"white"}
                    fontFamily={"PermanentMarkerRegular"}
                    my={0}
                  >
                    Give
                  </Text>
                  <Box width={"100%"}>
                    <Text
                      doBreak
                      uppercase
                      fontSize={["14px", "14px", "20px"]}
                      lineHeight={1.5}
                      color={"white"}
                      fontFamily={"AdineuePROBoldWeb"}
                    >
                      Give girls opportunities to be the focus of attention
                      among their peers.
                    </Text>
                  </Box>
                </Flex>
              </BoxOverImage>

              <Img
                fluid={{ ...quote2.childImageSharp.fluid }}
                loading={"eager"}
              ></Img>
            </Box>
          </Flex>
          <Flex
            flexDirection={["column", "row"]}
            alignItems="center"
            justifyItems={"center"}
          >
            <Box
              width={["100%"]}
              mr={[0, 4]}
              mt={5}
              css={css`
                position: relative;
              `}
            >
              <BoxOverImage>
                <Flex
                  width={"100%"}
                  flexDirection={"column"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Text
                    doBreak
                    uppercase
                    fontSize={["80px", "80px", "100px"]}
                    lineHeight={["116px", "116px", "141px"]}
                    color={"white"}
                    fontFamily={"PermanentMarkerRegular"}
                    my={0}
                  >
                    Play
                  </Text>
                  <Box width={"100%"}>
                    <Text
                      doBreak
                      uppercase
                      fontSize={["14px", "14px", "20px"]}
                      lineHeight={1.5}
                      color={"white"}
                      fontFamily={"AdineuePROBoldWeb"}
                    >
                      Play games where making connections is the point.
                    </Text>
                  </Box>
                </Flex>
              </BoxOverImage>

              <Img
                fluid={{ ...quote3.childImageSharp.fluid }}
                loading={"eager"}
              ></Img>
            </Box>
            <Box
              width={["100%"]}
              mt={5}
              ml={[0, 4]}
              css={css`
                position: relative;
              `}
            >
              <BoxOverImage>
                <Flex
                  width={"100%"}
                  flexDirection={"column"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Text
                    doBreak
                    uppercase
                    fontSize={["80px", "80px", "100px"]}
                    lineHeight={["116px", "116px", "141px"]}
                    color={"white"}
                    fontFamily={"PermanentMarkerRegular"}
                    my={0}
                  >
                    Create
                  </Text>
                  <Box width={"100%"}>
                    <Text
                      doBreak
                      uppercase
                      fontSize={["14px", "14px", "20px"]}
                      lineHeight={1.5}
                      color={"white"}
                      fontFamily={"AdineuePROBoldWeb"}
                    >
                      Create positive traditions on and off the field.
                    </Text>
                  </Box>
                </Flex>
              </BoxOverImage>
              <Img fluid={quote4.childImageSharp.fluid} loading={"eager"}></Img>
            </Box>
          </Flex>
        </Box>
      </InfoboxDetailed>
      <InfoboxDetailed
        as={"section"}
        bg={"seafoam"}
        headerText={<span>So why are relationships important?</span>}
        headerBackground={"transparent"}
        contentMaxWidth={"desktop"}
      >
        <Box bg={"white-panel"} mt={[5, 6]} px={[5, 5, 6]} py={[3, 6]}>
          <Flex
            maxWidth={"800px"}
            mx={"auto"}
            justifyContent={"center"}
            alignItems={"center"}
            flexDirection={"column"}
          >
            <Box width={"100%"}>
              <Text>
                Because the team friendships and coach-to-athlete support can
                extend far beyond the field.
              </Text>
            </Box>
            <Box width={"100%"}>
              <Text>
                These positive adult-to-child relationships also have
                long-lasting impacts because they build up different{" "}
                <span
                  css={css`
                    font-family: "AdineuePROBoldWeb";
                  `}
                >
                  protective factors
                </span>{" "}
                that can increase a child’s ability to be resilient when facing
                challenges in life.
              </Text>
            </Box>
          </Flex>
          <Flex
            maxWidth={"994px"}
            mx={"auto"}
            justifyContent={"center"}
            alignItems={"start"}
            css={css`
              flex-wrap: wrap;
            `}
          >
            <GraphicWithText
              mq={graphicMq}
              image={graphic1}
              width={["100%", "30%"]}
              px={5}
            >
              <Box width={"100%"}>
                <Text fontSize={["14px", "16px"]} lineHeight={["21px", "24px"]}>
                  <span
                    css={css`
                      font-family: "AdineuePROBlackWeb";
                      text-transform: uppercase;
                    `}
                  >
                    Attendance in{" "}
                    <span
                      css={css`
                        text-decoration: underline;
                      `}
                    >
                      school:
                    </span>
                  </span>
                  <br/>
                  For those who struggle to find connections, a caring adult
                  figure within the school environment can help make it a
                  positive experience. For those who struggle to find
                  connections, a caring adult figure within the school
                  environment can help make it a positive experience.
                </Text>
              </Box>
            </GraphicWithText>
            <GraphicWithText
              mq={graphicMq}
              image={graphic2}
              width={["100%", "30%"]}
              px={6}
            >
              <Box width={"100%"}>
                <Text
                  fontSize={["14px", "16px", "18px"]}
                  lineHeight={["21px", "24px", "27px"]}
                >
                  <span
                    css={css`
                      font-family: "AdineuePROBlackWeb";
                      text-transform: uppercase;
                    `}
                  >
                    Participation in{" "}
                    <span
                      css={css`
                        text-decoration: underline;
                      `}
                    >
                      group activities:
                    </span>
                  </span>
                  <br/>
                  Groups help to provide identity and ownership and can be great
                  places to meet caring adults that help guide positive
                  experiences.
                </Text>
              </Box>
            </GraphicWithText>
            <GraphicWithText
              mq={graphicMq}
              image={graphic3}
              width={["100%", "30%"]}
              px={6}
            >
              <Box width={"100%"}>
                <Text
                  fontSize={["14px", "16px", "18px"]}
                  lineHeight={["21px", "24px", "27px"]}
                >
                  <span
                    css={css`
                      font-family: "AdineuePROBlackWeb";
                      text-transform: uppercase;
                    `}
                  >
                    Understanding{" "}
                    <span
                      css={css`
                        text-decoration: underline;
                      `}
                    >
                      strengths and assets:
                    </span>
                  </span>
                  <br/>
                  Some kids need guidance to recognize their strengths and
                  understand who they are and how they relate to the world
                  around them.
                </Text>
              </Box>
            </GraphicWithText>
            <GraphicWithText
              mq={graphicMq}
              image={graphic4}
              width={["100%", "30%"]}
              px={6}
            >
              <Box width={"100%"}>
                <Text
                  fontSize={["14px", "16px", "18px"]}
                  lineHeight={["21px", "24px", "27px"]}
                >
                  <span
                    css={css`
                      font-family: "AdineuePROBlackWeb";
                      text-transform: uppercase;
                    `}
                  >
                    <span
                      css={css`
                        text-decoration: underline;
                      `}
                    >
                      Problem-solving
                    </span>{" "}
                    skills:
                  </span>
                  <br/>
                  Some kids may need the help of an adult to sharpen their
                  ability to solve problems.
                </Text>
              </Box>
            </GraphicWithText>
            <GraphicWithText
              mq={graphicMq}
              image={graphic5}
              width={["100%", "30%"]}
              px={6}
            >
              {" "}
              <Box width={"100%"}>
                <Text
                  fontSize={["14px", "16px", "18px"]}
                  lineHeight={["21px", "24px", "27px"]}
                >
                  <span
                    css={css`
                      font-family: "AdineuePROBlackWeb";
                      text-transform: uppercase;
                    `}
                  >
                    <span
                      css={css`
                        text-decoration: underline;
                      `}
                    >
                      Self-efficacy:
                    </span>
                  </span>
                  <br/>
                  Many kids have a hard time saying “I can” when they face
                  failure and obstacles. They need an adult to help encourage
                  and build efficacy.
                </Text>
              </Box>
            </GraphicWithText>
            <GraphicWithText
              mq={graphicMq}
              image={graphic6}
              width={["100%", "30%"]}
              px={6}
            >
              {" "}
              <Box width={"100%"}>
                <Text
                  fontSize={["14px", "16px", "18px"]}
                  lineHeight={["21px", "24px", "27px"]}
                >
                  <span
                    css={css`
                      font-family: "AdineuePROBlackWeb";
                      text-transform: uppercase;
                    `}
                  >
                    <span
                      css={css`
                        text-decoration: underline;
                      `}
                    >
                      Belonging
                    </span>{" "}
                    to a group or community:
                  </span>
                  <br/>
                  Adults leading groups and communities can help create a
                  support structure and belonging for kids to become more
                  resilient.
                </Text>
              </Box>
            </GraphicWithText>
            <GraphicWithText
              mq={graphicMq}
              image={graphic7}
              width={["100%", "30%"]}
              px={6}
            >
              {" "}
              <Box width={"100%"}>
                <Text
                  fontSize={["14px", "16px", "18px"]}
                  lineHeight={["21px", "24px", "27px"]}
                >
                  <span
                    css={css`
                      font-family: "AdineuePROBlackWeb";
                      text-transform: uppercase;
                    `}
                  >
                    <span
                      css={css`
                        text-decoration: underline;
                      `}
                    >
                      Contribution
                    </span>{" "}
                    to something meaningful:
                  </span>
                  <br/>
                  Adults can help empower and support kids as they pursue
                  opportunities to contribute to something challenging and
                  meaningful.
                </Text>
              </Box>
            </GraphicWithText>
            <GraphicWithText
              mq={graphicMq}
              image={graphic8}
              width={["100%", "30%"]}
              px={6}
            >
              {" "}
              <Box width={"100%"}>
                <Text
                  fontSize={["14px", "16px", "18px"]}
                  lineHeight={["21px", "24px", "27px"]}
                >
                  <span
                    css={css`
                      font-family: "AdineuePROBlackWeb";
                      text-transform: uppercase;
                    `}
                  >
                    <span
                      css={css`
                        text-decoration: underline;
                      `}
                    >
                      High expectations:
                    </span>
                  </span>
                  <br/>
                  Caring adults are key to setting and maintaining high
                  expectations and teaching kids the skills to be accountable as
                  they move toward their goals.
                </Text>
              </Box>
            </GraphicWithText>
            <GraphicWithText
              mq={graphicMq}
              image={graphic9}
              width={["100%", "30%"]}
              px={6}
            >
              {" "}
              <Box width={"100%"}>
                <Text
                  fontSize={["14px", "16px", "18px"]}
                  lineHeight={["21px", "24px", "27px"]}
                >
                  <span
                    css={css`
                      font-family: "AdineuePROBlackWeb";
                      text-transform: uppercase;
                    `}
                  >
                    Healthy{" "}
                    <span
                      css={css`
                        text-decoration: underline;
                      `}
                    >
                      family structure:
                    </span>
                  </span>
                  <br/>
                  Some families lack the important support infrastructure that
                  kids need, so kids may look toward other adults and peers for
                  support.
                </Text>
              </Box>
            </GraphicWithText>
          </Flex>
        </Box>
      </InfoboxDetailed>

      <InfoboxDetailed
        as={"section"}
        headerText={"A note on cultivating healthy competition:"}
        headerBackground={"yellow"}
        headerMaxWidth={"desktop"}
        contentMaxWidth={"desktop"}
        pb={0}
      >
        <Box bg={"white-panel"} mt={[5]} px={[5, 5, 6]} py={[3, 6]}>
          <Box
            width={"100%"}
            maxWidth={"800px"}
            mx={"auto"}
            justifyContent={"center"}
            alignItems={"center"}
            flexDirection={"column"}
          >
            <Heading
              as={"h2"}
              fontSize={["18px", "24px"]}
              lineHeight={["27px", "32px"]}
              fontFamily={"AdineuePROBlackWeb"}
            >
              Reconciling Competition
            </Heading>
            <Text>
              Competition is a kind of relationship between an athlete and her
              opponent, but unlike many relationships, it’s not about forging
              bonds and connecting. Competition is about winning.
            </Text>
            <Text>
              Girls who care deeply about how their behavior affects other
              people, or who tend toward empathy, may struggle with this
              dynamic. They may wonder: How can I be competitive and still be
              considerate? How can I be nice if I accidentally knock someone
              down to score a goal? <Source>18</Source>
            </Text>
            <Text>
              The answer, as with so much else, is context. Competition is
              essential to sports, and in the unique, safe space that we create
              for sports, one can be competitive while still being considerate.
            </Text>
            <Heading
              as={"h2"}
              mt={6}
              fontSize={["18px", "24px"]}
              lineHeight={["27px", "32px"]}
              fontFamily={"AdineuePROBlackWeb"}
            >
              How competition helps girls
            </Heading>
            <Text>
              Competition is a part of playing sports. Sports are an excellent
              arena in which to teach girls the competitive dynamics that occur
              in other aspects of life, such as the business world.
              <Source>19</Source>
            </Text>
            <Text>
              You can help girls be more competitive. To do so, you have to give
              them opportunities to practice competing. It may be hard for some
              girls to accept the “me versus you” nature of a competition, but
              competitiveness can be taught and practiced.<Source>20</Source>
            </Text>
            <Text
              uppercase
              fontSize={["18px", "24px"]}
              lineHeight={["27px", "32px"]}
              fontFamily={"AdineuePROBoldWeb"}
            >
              ''We care about winning, and we use competition as an incentive,
              but we’ve found a way to be friendly rivals. We have an ethical
              model that's compassionate as well as competitive.'' —Mariah
              Burton Nelson, author of{" "}
              <span
                css={css`
                  font-family: "AdineuePROBoldItalicWeb";
                `}
              >
                Are We Winning Yet? How Women are Changing Sports and Sports Are
                Changing Women
              </span>
              <Source>21</Source>
            </Text>
          </Box>
        </Box>
      </InfoboxDetailed>

      <Copyright pt={6} bg={"white"}/>
    </Layout>
  )
}

export default withProgress(Page, 1, 3)
