import React from "react"
import { Box, Flex } from "reflexbox"
import { Text } from "~/components/common/Typography"
import { mq as themeMq } from "~/components/common/theme"
import Img from "gatsby-image"

const GraphicWithText = ({ children, background, image, mq, ...props }) => {
  return (
    <Flex
      // my="4"
      // mx={"auto"}
      flexDirection={["column"]}
      alignItems="center"
      justifyContent="center"
      {...props}
    >
      {image && (
        <Box
          width={"100%"}
          css={[
            mq
              ? mq
              : themeMq({
                  width: ["200px", "200px", "350px"],
                  height: ["200px", "200px", "350px"],
                }),
          ]}
        >
          <Img
            fluid={image.childImageSharp.fluid}
            margin={"0 auto"}
            loading={"eager"}
          ></Img>
        </Box>
      )}

      <Box pb={[3, 6]} textAlign={["center"]} width={"100%"}>
        <Text
          fontSize={["13px", "18px"]}
          lineHeight={["27px", "27px"]}
          fontFamily={"AdineuePROBoldWeb"}
        >
          {children}
        </Text>
      </Box>
    </Flex>
  )
}

export default GraphicWithText
